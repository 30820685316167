@media (max-width: 979px) {
  .classi-search-widget {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.classi {
    padding: 32px 0;
    background-color: #FFF
}

.classi .margin-bottom {
    margin-bottom: 24px
}

.classi .margin-top {
    margin-top: 24px
}

.classi .margin-vertical {
    margin-bottom: 24px;
    margin-top: 24px
}

.classi .margin-left {
    margin-left: 24px
}

.classi .margin-right {
    margin-right: 24px
}

.classi .margin-both,.classi .margin-horizontal {
    margin-left: 24px;
    margin-right: 24px
}

.classi:not(.classi__inside-content) {
    background-image: linear-gradient(to bottom,rgba(255,255,255,.8) 0,rgba(255,255,255,.8) 100%),url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC);
    background-repeat: repeat
}

.classi a,.classi__link {
    display: block;
    width: 100%
}

@media screen and (max-width: 1280px) {
    .classi {
        padding:0
    }

    .classi .margin-bottom {
        margin-bottom: 16px
    }

    .classi .margin-top {
        margin-top: 16px
    }

    .classi .margin-vertical {
        margin-bottom: 16px;
        margin-top: 16px
    }

    .classi .margin-left {
        margin-left: 16px
    }

    .classi .margin-right {
        margin-right: 16px
    }

    .classi .margin-both,.classi .margin-horizontal {
        margin-left: 16px;
        margin-right: 16px
    }

    .classi__link amp-img {
        margin: 24px auto 4px
    }
}

@media screen and (max-width: 960px) {
    .classi__link amp-img {
        max-width:50%;
        margin: 24px auto 4px
    }
}

.classi__title {
    font-style: italic;
    font-stretch: condensed;
    font-weight: 400;
    color: #3b0256
}

.classi__item {
    width: 100%;
    background-color: #fff
}

.classi__item__info {
    padding: 8px
}

.classi__item__secao {
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: 700;
    font-stretch: condensed;
    text-transform: capitalize;
    font-family: "IBM Plex Sans Condensed";
}

.classi__item__secao.imoveis {
    color: #04bcd5
}

.classi__item__secao.carros {
    color: #cd181e
}

.classi__item__secao.motos {
    color: #940057
}

.classi__item__secao.agro {
    color: #009645
}

.classi__item__secao.negocios {
    color: #ea8b1c
}

.classi__item__secao.empregos {
    color: #f05a22
}

.classi__item__title {
    margin: 0 0 16px;
    font-stretch: condensed;
    overflow: hidden;
    display: -webkit-box;
    font-family: "IBM Plex Sans Condensed";
    height: 57px;
    line-height: 1.2;
}

.classi__item__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.classi__item__city {
    font-size: 12px;
    color: #626463;
    font-family: "IBM Plex Sans Condensed";
}

.classi .classi__items {
    flex-wrap: nowrap
}

.classi .classi__items .classi__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.classi .classi__items .classi__list_item {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1280px) {
    .classi .classi__items .classi__list_item {
        margin-bottom:24px
    }
}

.classi .classi__items .fixed-container {
    position: relative;
    width: 100%;
    height: 167px
}

@media screen and (max-width: 960px) {
    .classi .classi__items {
        flex-wrap:wrap
    }

    .classi .classi__items .fixed-container {
        width: 100%;
        height: auto;
        padding-top: 100%
    }
}

.classi .classi__items .fixed-container amp-img.contain img {
    object-fit: contain
}

.classi .classi__items .fixed-container amp-img.cover img {
    object-fit: cover
}

.classi .classi__items .fixed-container img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.classi__inside-content {
    width: 100%
}

.classi__inside-content .classi__title {
    display: none
}

.classi__inside-content .classi__items .classi__list_item {
    margin-left: 16px;
    margin-right: 16px
}

@media screen and (max-width: 1024px) and (orientation:portrait) {
    .classi__inside-content .classi__items .classi__list_item {
        width: calc((100% / 12) * 3 - (16px * 2));
        flex-basis: calc((100% / 12) * 3 - (16px * 2))
    }
}

@media screen and (min-width: 1024px) and (orientation:landscape) {
    .classi__inside-content .classi__items .classi__list_item {
        width: calc((100% / 12) * 3 - (16px * 2));
        flex-basis: calc((100% / 12) * 3 - (16px * 2))
    }
}

@media screen and (max-width: 823px) and (orientation:landscape) {
    .classi__inside-content .classi__link amp-img {
        max-width: 30%
    }

    .classi__inside-content .classi__items .classi__list_item {
        width: calc((100% / 12) * 3 - (16px * 2));
        flex-basis: calc((100% / 12) * 3 - (16px * 2))
    }
}

@media screen and (max-width: 414px) and (orientation:portrait) {
    .classi__inside-content .classi__items .classi__list_item {
        width: calc((100% / 12) * 6 - (16px * 2));
        flex-basis: calc((100% / 12) * 6 - (16px * 2))
    }

    .classi__inside-content .classi__items li:nth-child(n+3) {
        display: none !important
    }
}

@media screen and (max-width: 375px) and (orientation:portrait) {
    .classi__inside-content .classi__items .classi__list_item {
        width: calc((100% / 12) * 6 - (16px * 2));
        flex-basis: calc((100% / 12) * 6 - (16px * 2))
    }
}

.classi__items .classi__list_item {
  margin-left: 16px;
  margin-right: 16px;
}