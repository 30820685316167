.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  background-color: #fff;
}
.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}
.rhap_container svg {
  vertical-align: initial;
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
  margin-left: 8px;
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
  margin-right: 8px;
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;
}
.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 8px;
}

.rhap_progress-section {
  display: none;
}

.rhap_controls-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.rhap_additional-controls {
  display: none;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 6px;
  color: #ccc;
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.rhap_play-pause-button svg {
  border-radius: 50%;
}
.rhap_play-pause-button svg:hover {
}
.rhap_play-pause-button svg path {
}
.rhap_play-pause-button {
  font-size: 60px;
  width: 60px;
  height: 60px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

.rhap_volume-button {
  flex: 0 0 16px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: #868686;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
  width: 240px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
}
.rhap_volume-bar-area:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 2px;
  background: #dddddd;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  left: 0;
  top: -3px;
  background: #868686;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}
.rhap_volume-indicator:hover {
  opacity: 0.9;
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: red;
  border-radius: 2px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}
.rhap_button-clear:active {
  opacity: 0.95;
}
.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}
