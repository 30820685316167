html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textarea__assine {
  font-family: Fira Sans;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.Item.desktop {
  display: none;
}
@media (min-width: 980px) {
  .Item.mobile {
    display: none;
  }
  .Item.desktop {
    display: flex;
  }
}

article {
  width: 100%;
}
/* .opopular article h2 {
  color: #194392;
} */
article blockquote {
  margin: 0px 0px 24px 40px;
  /* padding-left: 20px; */
  /* border-left: 4px solid rgba(0,0,0,0.2); */
  text-align: left;
  max-width: calc(960px - 20px);
  width: calc(100% - 40px);
}
article blockquote p {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  margin-bottom: 0px !important;
  position: relative;
  width: calc(100% - 30px) !important;
}
@media (min-width: 980px) {
  article blockquote p {
    letter-spacing: 0.6px !important;
    line-height: 35px !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    margin-bottom: 0px !important;
  }
}
article blockquote p::before {
  content: "“";
  position: absolute;
  font-size: 60px;
  left: -40px;
  top: 20px;
}
article a {
  text-decoration: underline;
}
article h2 {
  font-size: 22px;
  line-height: 35px;
  letter-spacing: 0.2px;
  margin-bottom: '$6';
}
article h3 {
  margin-bottom: 12px !important;
  font-size: 21px !important;
  line-height: 22px !important;
}
article h4 {
  margin-bottom: 12px !important;
  font-size: 18px !important;
  line-height: 22px !important;
}
@media (min-width: 980px) {
  article h3 {
    margin-bottom: 12px !important;
    font-size: 21px !important;
    line-height: 30px !important;
    letter-spacing: 0.2px !important;
  }
  article h4 {
    margin-bottom: 12px !important;
    font-size: 25px !important;
    line-height: 30px !important;
    letter-spacing: 0.2px !important;
  }
}
article > ul,
article > ol {
  padding: 0px;
  margin: 0px;
  margin-left: 36px;
  max-width: calc(960px - 20px);
  margin-bottom: 24px;
  width: calc(100% - 36px) !important;
}
article > ul li,
article > ol li {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 35px;
  margin-bottom: 0px !important;
  width: calc(100% - 36px) !important;
}
article iframe.youtube-video {
  min-height: 520px;
  width: 100%;
}
.article-youtube-embed {
  height: 0;
  margin-bottom: 24px;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* width: calc(100% - 48px) !important; */
}
.article-youtube-embed iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter-tweet,
.twitter-tweet-rendered {
  margin: 0px !important;
  margin-left: -24px;
}

.carousel .slide p,
.carousel-slider .slide p {
  text-align: left;
}

.carousel ul.thumbs {
  display: contents !important;
}

@media (min-width: 980px) {
  .isDesktop {
    display: flex;
    visibility: visible;
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .isDesktop.debugAds {
    display: flex !important;
  }
  .isMobile {
    display: none;
    visibility: hidden;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media (max-width: 979px) {
  .isDesktop {
    display: none;
    visibility: hidden;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .isMobile {
    display: flex;
    visibility: visible;
    margin-top: 32px;
    margin-bottom: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .isMobile.debugAds {
    display: flex !important;
  }
}

footer {
  width: 100%;
}

input[type="range"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  background-color: #ddd;
  height: 8px;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  background-color: #808080;
  border-radius: 50%;
  box-shadow: -200px 0 0 195px #b0b0b0;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  width: 8px;
  height: 8px;
  background-color: #808080;
  border-radius: 50%;
  box-shadow: -200px 0 0 195px #b0b0b0;
}

.topbar {
  width: 100%;
  position: relative;
}

.topbar-visible {
  top: 0;
}

.topbar-hidden {
  top: -500px;
}

.pivot.topbar-fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.topbar-hidden {
  top: -500px;
}

@media (min-width: 1016px) {
  .topbar.topbar-fixed {
    min-height: 182px;
  }
}

@media (max-width: 1015px) {
  .topbar.topbar-fixed {
    min-height: 52px;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  display: block !important;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 98%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

#alright-adslot {
  margin-bottom: 16px;
}

.alright-debug-slot {
  display: flex !important;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
}

.alright-debug-slot.not-used {
  background: #626463 !important;
  animation: none;
  -z-animation: none;
  -webkit-animation: none;
}

@-webkit-keyframes rainbow {
  0% {
    background-position:0% 82%
  }
  50% {
    background-position:100% 19%
  }
  100% {
    background-position:0% 82%
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}
@keyframes rainbow { 
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}
